import React from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainRed,
  navyBlue,
  lightNavyBlue,
  lightBlue,
  mainBlue,
  transHover,
  aku,
  innerWidth,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";
import { moveBgColor } from "../components/common/animations";
import Collapsible from "react-collapsible";
import logo from "../images/tenalphas-moving-content.jpg";

const Wrapper = styled.div`
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 124px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
    padding-bottom: 120px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 180px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .featured-testimonial {
    display: flex;
    margin-top: 20px;
    padding-bottom: 30px;
    position: relative;
    flex-direction: column;
    @media ${screen.small} {
      margin-top: 45px;
      flex-direction: row;
      padding-bottom: 60px;
    }
    @media ${screen.large} {
      padding-bottom: 90px;
    }

    &__person-img {
      width: 100%;
      max-width: 420px;
      margin-right: 45px;
      @media ${screen.small} {
        max-width: 260px;
      }
      @media ${screen.medium} {
        max-width: 420px;
        margin-right: 90px;
      }

      &--desktop {
        display: none;
        @media ${screen.small} {
          display: block;
        }
      }
    }

    &__texts-container {
      align-self: flex-end;

      .msg {
        color: ${navyBlue};
        margin-top: 5px;
        max-width: 1050px;

        p {
          font-size: 1rem;
          font-family: ${gotham};
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 1.25rem;
          }
          @media ${screen.medium} {
            font-size: 1.3rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: ${navyBlue};
          text-decoration: underline;
          transition: ${transHover};
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }

      .fullname {
        color: ${navyBlue};
        font-family: ${gothamBold};
        font-size: 1.1rem;
        margin-top: 15px;
        @media ${screen.medium} {
          margin-top: 45px;
        }
      }

      .company {
        color: ${navyBlue};
        font-family: ${gotham};
        font-size: 1.1rem;
        margin-top: 5px;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-image: linear-gradient(
        45deg,
        ${navyBlue},
        ${lightNavyBlue},
        ${mainBlue},
        ${lightBlue}
      );
      background-size: 370%;
      animation: ${moveBgColor} 12s infinite alternate;
      display: block;
      position: absolute;
      bottom: 0;
    }

    .subheading {
      color: ${navyBlue};
      font-family: ${aku};
      font-size: 1.7rem;
      font-weight: 400;
      @media ${screen.medium} {
        font-size: 2rem;
      }

      &--desktop {
        display: none;
        margin-bottom: 40px;
        @media ${screen.small} {
          display: block;
        }
      }

      &--mobile {
        font-family: ${gotham};
        font-size: 1.2rem;
        margin-bottom: 20px;
        @media ${screen.small} {
          display: none;
        }
      }
    }

    .person-info-container {
      &--desktop {
        display: none;
        @media ${screen.small} {
          display: block;
        }
      }

      &--mobile {
        display: flex;
        margin-bottom: 10px;
        @media ${screen.small} {
          display: none;
        }

        .img {
          width: 100%;
          padding-right: 20px;
        }

        .details {
          color: ${navyBlue};
          max-width: 120px;
          width: 100%;
          align-self: flex-end;

          .fullname {
            font-family: ${gothamBold};
          }

          .position {
            font-family: ${gotham};
          }

          .company {
            font-family: ${gotham};
          }
        }
      }
    }
  }

  .testimonial-list {
    &__item {
      display: flex;
      position: relative;
      padding-top: 21px;
      padding-bottom: 21px;
      @media ${screen.small} {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media ${screen.large} {
        padding-top: 90px;
        padding-bottom: 90px;
      }
      &:last-child {
        margin-bottom: 0;
      }

      .person-img {
        width: 100%;
        max-width: 200px;
        margin-right: 45px;
        @media ${screen.medium} {
          max-width: 250px;
          margin-right: 90px;
        }
      }

      .texts-container {
        .msg {
          color: ${navyBlue};
          max-width: 1170px;

          p {
            font-family: ${gotham};
            margin-bottom: 20px;
            font-size: 1.1rem;
          }

          a {
            color: ${navyBlue};
            text-decoration: underline;
            transition: ${transHover};
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainRed};
              }
            }
          }
        }

        .fullname {
          color: ${navyBlue};
          font-family: ${gothamBold};
          font-size: 1.1rem;
          margin-top: 15px;
          @media ${screen.medium} {
            margin-top: 45px;
          }
        }

        .company {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.1rem;
          margin-top: 5px;
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-image: linear-gradient(
          45deg,
          ${navyBlue},
          ${lightNavyBlue},
          ${mainBlue},
          ${lightBlue}
        );
        background-size: 370%;
        animation: ${moveBgColor} 10s infinite alternate;
        display: block;
        position: absolute;
        bottom: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    &--desktop {
      display: none;
      @media ${screen.small} {
        display: block;
      }
    }

    &--mobile {
      @media ${screen.small} {
        display: none;
      }

      .fullname {
        color: ${navyBlue};
        font-family: ${gothamBold};
      }

      .position {
        color: ${navyBlue};
        font-family: ${gotham};
      }

      .company {
        color: ${navyBlue};
        font-family: ${gotham};
      }

      .msg {
        color: ${navyBlue};
        font-family: ${gotham};
        margin-top: 15px;
        a {
          color: ${navyBlue};
          display: block;
          text-decoration: underline;
          margin-top: 2px;
        }
      }

      .photo {
        margin-top: 10px;
        /* max-width: 200px; */
        max-width: 145px;
      }

      .arrow {
        display: block;
        width: 22px;
        position: absolute;
        right: 5px;
        top: 40px;

        &--down {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

const TestimonialsPage = (props) => {
  const pageTitle = props.data.content.data.page
    ? props.data.content.data.page.text
    : `Testimonials`;
  const titleTag = props.data.content.data.title_tag
    ? props.data.content.data.title_tag
    : `Testimonials`;
  const metaDescription = props.data.content.data.meta_description
    ? props.data.content.data.meta_description
    : `Testimonials`;

  const testimonialId =
    props.location.state === null
      ? props.data.testimonials.edges[0].node.id
      : props.location.state &&
        typeof props.location.state.testimonialId !== "undefined"
      ? props.location.state.testimonialId
      : props.data.testimonials.edges[0].node.id;

  // Filter testimonilas to be the featured testimonial based on ID.
  const featuredTestimonial = props.data.testimonials.edges.filter(
    (testimonial) => testimonialId === testimonial.node.id
  );

  const testimonials = props.data.testimonials.edges.filter(
    (testimonial) => testimonial.node.id !== testimonialId
  );

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div className="featured-testimonial">
          <h3 className="subheading subheading--mobile">
            Our customers love what we do.
          </h3>
          <div className="person-info-container person-info-container--mobile">
            <div className="img">
              {featuredTestimonial[0].node.data.image.gatsbyImageData && (
                <GatsbyImage
                  image={
                    featuredTestimonial[0].node.data.image &&
                    featuredTestimonial[0].node.data.image.gatsbyImageData
                  }
                  alt={
                    featuredTestimonial[0].node.data.full_name.text ||
                    "Person photograph"
                  }
                />
              )}
            </div>
            <div className="details">
              <p className="fullname">
                {featuredTestimonial[0].node.data.full_name.text}
              </p>
              <p className="position">
                {featuredTestimonial[0].node.data.title_occupation}
              </p>
              <p className="company">
                {featuredTestimonial[0].node.data.company}
              </p>
            </div>
          </div>
          <div className="featured-testimonial__person-img featured-testimonial__person-img--desktop">
            {featuredTestimonial[0].node.data.image.gatsbyImageData && (
              <GatsbyImage
                image={
                  featuredTestimonial[0].node.data.image &&
                  featuredTestimonial[0].node.data.image.gatsbyImageData
                }
                alt={
                  featuredTestimonial[0].node.data.full_name.text ||
                  "Person photograph"
                }
              />
            )}
          </div>
          <div className="featured-testimonial__texts-container">
            <h3 className="subheading subheading--desktop">
              OUR CUSTOMERS LOVE WHAT WE DO
            </h3>
            <div
              className="msg"
              dangerouslySetInnerHTML={{
                __html: featuredTestimonial[0].node.data.message.html,
              }}
            />
            <div className="person-info-container person-info-container--desktop">
              <p className="fullname">
                {featuredTestimonial[0].node.data.full_name.text}
              </p>
              <p className="company">
                {featuredTestimonial[0].node.data.title_occupation}
                {featuredTestimonial[0].node.data.title_occupation && ", "}

                {featuredTestimonial[0].node.data.company}
              </p>
            </div>
          </div>
        </div>
        <ul className="testimonial-list testimonial-list--desktop">
          {testimonials.map((testimonial, i) => (
            <li key={i} className="testimonial-list__item">
              <div className="person-img">
                {testimonial.node.data.image.gatsbyImageData && (
                  <GatsbyImage
                    image={testimonial.node.data.image.gatsbyImageData}
                    alt={
                      testimonial.node.data.full_name.text ||
                      "Person photograph"
                    }
                  />
                )}
              </div>
              <div className="texts-container">
                <div
                  className="msg"
                  dangerouslySetInnerHTML={{
                    __html: testimonial.node.data.message.html,
                  }}
                />
                <h4 className="fullname">
                  {testimonial.node.data.full_name.text &&
                    testimonial.node.data.full_name.text}
                </h4>
                <p className="company">
                  {testimonial.node.data.title_occupation &&
                    testimonial.node.data.title_occupation}
                  {testimonial.node.data.title_occupation && ", "}
                  {testimonial.node.data.company &&
                    testimonial.node.data.company}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <ul className="testimonial-list testimonial-list--mobile">
          {testimonials.map((testimonial, i) => (
            <li key={i} className="testimonial-list__item">
              <Collapsible
                triggerWhenOpen={
                  <div>
                    <h3 className="fullname">
                      {testimonial.node.data.full_name.text}
                      right
                    </h3>

                    <p className="position">
                      {testimonial.node.data.title_occupation &&
                        testimonial.node.data.title_occupation}
                    </p>
                    <p className="company">
                      {testimonial.node.data.company &&
                        testimonial.node.data.company}
                    </p>
                    <span className="arrow arrow--down">
                      <Arrow />
                    </span>
                  </div>
                }
                trigger={
                  <div>
                    <h3 className="fullname">
                      {testimonial.node.data.full_name.text}
                      right
                    </h3>

                    <p className="position">
                      {testimonial.node.data.title_occupation &&
                        testimonial.node.data.title_occupation}
                    </p>
                    <p className="company">
                      {testimonial.node.data.company &&
                        testimonial.node.data.company}
                    </p>
                    <span className="arrow">
                      <Arrow />
                    </span>
                  </div>
                }
                transitionTime={150}
              >
                <div
                  className="msg"
                  dangerouslySetInnerHTML={{
                    __html: testimonial.node.data.message.html,
                  }}
                />
                <div className="photo">
                  {testimonial.node.data.image.gatsbyImageData && (
                    <GatsbyImage
                      image={testimonial.node.data.image.gatsbyImageData}
                      alt={
                        testimonial.node.data.full_name.text ||
                        "Person photograph"
                      }
                    />
                  )}
                </div>
              </Collapsible>
            </li>
          ))}
        </ul>
      </Wrapper>
    </Layout>
  );
};

export default TestimonialsPage;

export const dataQuery = graphql`
  {
    content: prismicTestimonialsPage {
      data {
        title_tag
        meta_description
        page {
          text
        }
      }
    }
    testimonials: allPrismicTestimonial {
      edges {
        node {
          id
          data {
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            full_name {
              text
            }
            title_occupation
            company
            message {
              html
            }
          }
        }
      }
    }
  }
`;
